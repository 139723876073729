:root {
  --primary: #007BFF;
  --secondary: #00A1FF;
  --accent: #ff6600;
  --background: rgba(255, 255, 255, 0.3);
  --text: #333;
  --nav-bg: rgba(255, 255, 255, 0.9);
  --primary-rgb: 0, 123, 255;
  --secondary-rgb: 0, 161, 255;
  --vertical-bg: linear-gradient(135deg, rgba(0, 123, 255, 0.05), rgba(0, 255, 128, 0.05));
  --vertical-shadow: rgba(0, 123, 255, 0.1);
}

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
}

body {
  overflow-y: scroll;
}

.App {
  width: 100vw;
  overflow-x: hidden;
  position: relative;
}

.content {
  width: 100%;
  overflow-x: hidden;
  padding-right: calc(100vw - 100%);
}

.snap-section {
  scroll-snap-align: start;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 5%;
  box-sizing: border-box;
}

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: var(--background);
  color: var(--text);
  overflow: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  min-height: 100vh;
  text-shadow: 0 1px 2px rgba(255, 255, 255, 0.8);
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

#tsparticles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.content {
  position: relative;
  z-index: 1;
  padding: 4rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: var(--nav-bg);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 1.5rem 2.5rem;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
}

nav {
  display: flex;
  gap: 1.5rem;
}

nav button {
  background-color: transparent;
  border: 2px solid var(--primary);
  color: var(--primary);
  padding: 0.5rem 1rem;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

nav button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.3), transparent);
  transition: all 0.4s;
}

nav button:hover::before {
  left: 100%;
}

nav button:hover {
  background-color: var(--primary);
  color: white;
  transform: scale(1.05);
  box-shadow: 0 0 15px rgba(0, 123, 255, 0.5);
}

nav button:active {
  transform: scale(0.95);
}

h1 {
  font-size: 2.5em;
  color: #1a1a1a;
  margin: 0 0 1rem 0;
  font-weight: 700;
}

h2 {
  font-size: 2em;
  color: #1a1a1a;
  margin-bottom: 1.5rem;
  font-weight: 700;
}

h3 {
  font-size: 1.75em;
  color: #1a1a1a;
  margin-bottom: 1rem;
  font-weight: 600;
}

.home, .portfolio-highlights, .team, .about-us {
  margin: 2rem auto;
  text-align: center;
  padding: 2rem;
  border-radius: 15px;
  max-width: 90%;
  width: 100%;
  box-sizing: border-box;
}

p {
  font-size: 1.2em;
  line-height: 1.6;
  color: var(--text);
}

.highlight-item, .team-member, .vertical-item, .portfolio-item {
  background: var(--vertical-bg);
  padding: 1.5rem;
  border-radius: 10px;
  margin-bottom: 1.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 3px 10px var(--vertical-shadow);
}

.highlight-item:hover, .team-member:hover, .vertical-item:hover, .portfolio-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px var(--vertical-shadow);
}

.team-grid, .vertical-grid, .portfolio-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Ensure it fits the viewport height */
}

.home h1 {
  font-size: 4rem;
  margin-bottom: 1rem;
  color: var(--primary);
}

.home h2 {
  font-size: clamp(1.8rem, 5vw, 4rem);
  width: 100%;
  padding: 0 1rem;
  text-align: center;
  line-height: 1.2;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}

.home p {
  font-size: 1.2rem;
  max-width: 800px;
}

button {
  background: linear-gradient(45deg, var(--primary), var(--secondary));
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    align-items: flex-start;
  }

  nav {
    flex-direction: column;
    width: 100%;
    margin-top: 1rem;
  }

  nav button {
    width: 100%;
    margin: 0.5rem 0;
  }

  .home h1 {
    font-size: 2.5rem;
  }

  .home h2 {
    font-size: 8rem;
    width: 100%;
  }

  .hero-section h1 {
    font-size: 2rem;
  }

  .hero-section h2 {
    font-size: 3.5rem;
  }

  .neon-text {
    font-size: 6rem;
    padding: 0 1rem;
  }

  .vertical-columns {
    flex-direction: column;
  }

  .vertical-column {
    width: 100%;
  }

  .vertical-item {
    height: auto;
  }

  .portfolio-grid {
    grid-template-columns: 1fr;
  }

  .team-grid {
    grid-template-columns: 1fr;
  }

  .content {
    padding: 2rem 1rem;
  }
}

.hero-section {
  background-color: transparent;
  text-align: center;
  padding: 4rem 0;
  border-radius: 10px;
  margin-bottom: 4rem;
  min-height: 100vh; /* Ensure it fits the viewport height */
}

.hero-section h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  color: var(--primary);
  padding: 0 1rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.2), 0 0 10px #00ffcc, 0 0 20px #00ffcc, 0 0 30px #00ffcc;
}

.hero-section h2 {
  font-size: 10rem;
  margin-bottom: 4rem;
  color: var(--secondary);
  padding: 0 1rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.2), 0 0 5px #00ffcc, 0 0 10px #00ffcc, 0 0 15px #00ffcc;
}

.mission-section {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-height: 100vh; /* Ensure it fits the viewport height */
}

.neon-text-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: transparent;
}

.neon-text {
  font-size: clamp(1rem, 2.5vw, 2rem); /* Further reduced font size */
  color: var(--primary);
  margin: 0;
  padding: 1rem 2rem;
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  text-align: center;
  line-height: 1.5;
  font-weight: bold;
  max-width: 95%; /* Increased max-width */
  background-color: var(--vertical-bg);
  border-radius: 10px;
  box-shadow: 0 0 10px var(--vertical-shadow), 0 0 20px var(--vertical-shadow), 0 0 30px var(--vertical-shadow);
}

.snap-section {
  scroll-snap-align: start;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  box-sizing: border-box;
}

.verticals-section {
  background: var(--vertical-bg);
  border-radius: 15px;
  padding: 4rem 2rem;
  margin: 5rem 0;
  box-shadow: 0 0 30px var(--vertical-shadow);
}

.verticals-section h3 {
  font-size: 2.4rem;
  margin-bottom: 3rem;
  text-align: center;
  color: var(--primary);
  text-shadow: 0 0 10px var(--vertical-shadow);
  padding: 0 1rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}

.vertical-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.vertical-item {
  background: var(--vertical-bg);
  padding: 1.5rem;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 3px 10px var(--vertical-shadow);
}

.vertical-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px var(--vertical-shadow);
}

.vertical-item h4 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: var(--primary);
}

.vertical-item p {
  font-size: 1rem;
  color: var(--text);
}

@media (max-width: 768px) {
  .vertical-grid {
    grid-template-columns: 1fr;
  }
}

section {
  scroll-snap-align: start;
  padding: 7rem 0;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
}

section:not(:first-child):not(:nth-child(2))::before {
  backdrop-filter: blur(10px);
  opacity: 1;
}

.home h1, .home h2, .home p {
  opacity: 1;
  transform: translateY(0);
}

.blur-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  pointer-events: none;
  transition: backdrop-filter 0.3s ease;
}

@media (max-width: 768px) {
  header {
    padding: 1rem 1.5rem;
  }

  .header-content {
    flex-direction: column;
    align-items: flex-start;
  }

  nav {
    margin-top: 1rem;
    flex-wrap: wrap;
    justify-content: center;
  }

  nav button {
    font-size: 0.9rem;
    padding: 0.4rem 0.8rem;
    margin: 0.3rem;
  }

  .home h1 {
    font-size: 2.5rem;
  }

  .home h2 {
    font-size: 6rem;
  }

  .hero-section h1 {
    font-size: 2rem;
  }

  .hero-section h2 {
    font-size: 3.5rem;
  }

  .neon-text {
    font-size: 1.5rem;
  }

  .verticals-section h3 {
    font-size: 1.5rem;
  }

  .vertical-columns {
    flex-direction: column;
  }

  .vertical-column {
    width: 100%;
  }

  .vertical-item {
    height: auto;
    min-height: 150px;
  }

  .content {
    padding: 2rem 1rem;
  }
}

.content-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background-color: var(--vertical-bg);
  border-radius: 10px;
  box-shadow: 0 0 10px var(--vertical-shadow), 0 0 20px var(--vertical-shadow), 0 0 30px var(--vertical-shadow);
}

.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.portfolio-item {
  background: rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 3px 10px var(--vertical-shadow);
}

.portfolio-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px var(--vertical-shadow);
}

.portfolio-item h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: var(--primary);
}

.portfolio-item p {
  font-size: 1rem;
  color: var(--text);
}

@media (max-width: 768px) {
  .content-container {
    padding: 1rem;
  }

  .portfolio-grid {
    grid-template-columns: 1fr;
  }
}

.portfolio {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Ensure it fits the viewport height */
}

.portfolio-header {
  background-color: transparent;
  text-align: center;
  padding: 4rem 0;
  border-radius: 10px;
  margin-bottom: 4rem;
  min-height: 100vh; /* Ensure it fits the viewport height */
}

.portfolio-items {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-height: 100vh; /* Ensure it fits the viewport height */
}

.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.portfolio-item {
  background: rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 3px 10px var(--vertical-shadow);
}

.portfolio-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px var(--vertical-shadow);
}

.portfolio-item h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: var(--primary);
}

.portfolio-item p {
  font-size: 1rem;
  color: var(--text);
}

.team, .about-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Ensure it fits the viewport height */
}

.team-header, .about-us-header {
  background-color: transparent;
  text-align: center;
  padding: 4rem 0;
  border-radius: 10px;
  margin-bottom: 4rem;
  min-height: 100vh; /* Ensure it fits the viewport height */
}

.team-members, .about-us-content {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-height: 100vh; /* Ensure it fits the viewport height */
}

.team-grid, .content-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.team-member, .partner {
  background: var(--vertical-bg);
  padding: 1.5rem;
  border-radius: 10px;
  margin-bottom: 1.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 3px 10px var(--vertical-shadow);
  color: var(--text);
  text-align: center;
}

.team-member:hover, .partner:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px var(--vertical-shadow);
}

.partners-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.partner-pfp {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 1rem;
  box-shadow: 0 0 10px var(--vertical-shadow);
}

.about-us-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
}

.about-us-section p {
  text-align: center;
  max-width: 800px;
}

.about-us {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-us-section {
  margin-bottom: 2rem;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-us-section p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--text);
}

.about-us-section svg {
  margin-bottom: 1rem;
}